.header {
  height: 100px;
  background-color: #fabe00;

  .logo {
    width: 168px;
    height: 59px;
    float: left;
    margin: 20px 102px 0 0;
    border: none;
  }

  .item {
    font-size: 16px;
    height: 100%;
    position: relative;
    float: left;
    position: relative;
    z-index: 11;

    .inner {
      line-height: 100px;
      padding: 0 26px;
      display: block;
      font-weight: bold;
    }

    .drop-down {
      display: none;
      background-color: #fff;
      padding: 10px 0;
      width: 230px;
      box-shadow: 0 0 10px rgba(75, 72, 72, 0.3);

      .entry {
        display: block;
        line-height: 50px;
        padding: 0 40px;

        &:hover {
          background-color: #fabe00;
        }
      }
    }

    &:hover {
      .inner {
        color: #fabe00;
        background-color: #221814;
      }

      .drop-down {
        display: block;
        position: absolute;
      }
    }
  }
}
