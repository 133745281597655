.footer {
  /* height: 260px; */
  background-color: #4B4848;
  color: #fff;
  text-align: center;
  line-height: 30px;
  padding: 50px 0;
  margin-top: 30px;

  a {
    color: #fff;
  }
}
