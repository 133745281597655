@font-face {
  font-family: 'icomoon';
  src:
    url('fonts/icomoon.ttf?vt9llq') format('truetype'),
    url('fonts/icomoon.woff?vt9llq') format('woff'),
    url('fonts/icomoon.svg?vt9llq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cate-1:before {
  content: "\e900";
}
.icon-cate-2:before {
  content: "\e901";
}
.icon-cate-3:before {
  content: "\e902";
}
.icon-cate-4:before {
  content: "\e903";
}
.icon-cate-5:before {
  content: "\e904";
}
.icon-cate-6:before {
  content: "\e905";
}
.icon-cate-7:before {
  content: "\e906";
}
.icon-cate-8:before {
  content: "\e907";
}
.icon-cate-9:before {
  content: "\e908";
}
.icon-cate-10:before {
  content: "\e909";
}
.icon-cate-11:before {
  content: "\e90a";
}
.icon-cate-12:before {
  content: "\e90b";
}
.icon-cate-13:before {
  content: "\e90c";
}
.icon-cate-14:before {
  content: "\e90d";
}
.icon-cate-15:before {
  content: "\e90e";
}
.icon-phone:before {
  content: "\e90f";
}
.icon-qrcode:before {
  content: "\e910";
}
.icon-vip-1:before {
  content: "\e911";
}
.icon-vip-2:before {
  content: "\e912";
}
.icon-vip-3:before {
  content: "\e913";
}
.icon-vip-4:before {
  content: "\e914";
}
.icon-arrow-left:before {
  content: "\e915";
}
.icon-arrow-right:before {
  content: "\e916";
}
.icon-arrow-up:before {
  content: "\e917";
}
.icon-arrow-down:before {
  content: "\e918";
}
.icon-close:before {
  content: "\e919";
}
.icon-checkbox:before {
  content: "\e91a";
}
.icon-checkbox-checked:before {
  content: "\e91b";
}
.icon-search:before {
  content: "\e91c";
}
.icon-cut:before {
  content: "\e91d";
}
.icon-add:before {
  content: "\e91e";
}
.icon-store:before {
  content: "\e91f";
}
.icon-cart:before {
  content: "\e920";
}
.icon-squares-four-fill:before {
  content: "\e921";
}
.icon-stack-fill:before {
  content: "\e922";
}
