.c-search-bar {
  float: right;
  background-color: #fff;
  padding: 0 20px 0 10px;
  margin-top: 34px;
  width: 200px;
  height: 36px;
  border-radius: 4px;
  position: relative;

  input {
    border: none;
    height: 100%;
  }

  .icon-search {
    position: absolute;
    right: 10px;
    font-size: 20px;
    top: 8px;
    cursor: pointer;
  }
}
