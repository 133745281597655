.tools {
  position: fixed;
  bottom: 99px;
  right: 20px;
  transition: width .1s ease-out;
  width: 60px;
  height: 183px;
  background-color: rgba(242,242,242,1);
  box-sizing: border-box;
  border: 1px solid #d7d7d7;
  box-shadow: none;
  text-align: center;
  z-index: 222;
  cursor: default;
}

.tools .item {
  display: block;
  padding: 15px 0;
  margin: 0 13px;
  border-bottom: 1px solid #d7d7d7;
  opacity: .5;
}

.tools .item:last-child {
  border: none;
}

.tools .item:before {
  font-size: 30px;
  color: #000;
}

.tools .item:hover {
  opacity: 1;
}

.tools .item:hover:before {
  color: #fabe00;
}

.tools .tel {
  display: none;
  width: 168px;
  height: 62px;
  position: absolute;
  right: 58px;
  top: 60px;
  background-color: #f2f2f2;
  font-size: 18px;
  font-style: normal;
  line-height: 62px;
  border: 1px solid #d7d7d7;
  border-right: none;
  color: #fabe00;
}

.tools .item:hover .tel {
  display: block;
}

.tools-transition {
  animation-name: tools;
  animation-duration: .3s;
  animation-timing-function: linear;
}

@keyframes tools {
  0% {
    opacity: 0;
    right: 0;
  }

  100% {
    opacity: 1;
    right: 58px;
  }
}

.tools .wxs {
  display: none;
  width: 160px;
  height: 190px;
  position: absolute;
  top: -1px;
  right: 65px;
  opacity: 1;
}

.tools .item:hover .wxs {
  display: block;
}

.tools .wxs img {
  width: 100%;
}

.tools .icon-wx {
  opacity: 1;
  display: block;
  margin: 0 auto 10px;
  width: 86px;
  height: 86px;
  padding: 0;
  border: none;
}

.tools .wxs .icon-tool {
  width: 130px;
  margin: 0 auto;
}

.tools .wxs em {
  display: block;
  color: #fff;
  font-style: normal;
}
