/* [class^="icon-"], [class*=" icon-"] {
  line-height: inherit;
  font-size: 16px;
  text-align: center;
  vertical-align: top;
  display: inline-block;
} */

html {
  font-family: -apple-system, "PingFang SC", "Microsoft YaHei", "Hiragino Sans GB", "SF UI Text", "Helvetica Neue", Arial, "WenQuanYi Zen Hei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  height: 100%;
  color: #4b4848;
  background-color: #f1f1f1;
  font-size: 14px;
}

body {
  margin: 0;
  min-width: 1260px;
}

::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  overflow: hidden;
}

::-webkit-scrollbar-track {
  box-shadow: inset 1px 0 0 rgba(0,0,0,.1);
}

::-webkit-scrollbar-thumb {
  background: #c2c2c2;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:window-inactive {
  background-color: rgba(194, 194, 194,.7);
}

div, p, span, strong, a, ul, ol, li, i, img, h2, h3, h4, h5, form, input, textarea {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

ul, ol, li {
  list-style: none;
}

* {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  text-decoration: none;
  color: #221814;
}

.mod-box {
  width: 1310px;
  margin: 0 auto;

  &.mod-box-bg-w {
    background-color: #fff;
  }
}

.m-t-20 {
  margin-top: 20px;
}

.fix {
  zoom: 1;
}

.fix:after {
  display: block;
  content: '';
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}

.fl-l { float: left; }

.fl-r { float: right; }

.elis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-middle {
  text-align: center;

  .v-middle-content {
    display: inline-block;
    vertical-align: middle;
  }

  &:after {
    content: '';
    width: 0;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }
}

.bread-crumbs {
  height: 60px;
  line-height: 60px;
}

.inner-menu {
  line-height: 80px;
  font-size: 16px;
  color: #e5e5e5;

  .innter-menu-item {
    color: #4b4848;
    padding: 0 40px;

    &.inner-menu-active {
      color: #fabe00;
    }
  }
}

.line-1px {
  border-top: 1px solid #e5e5e5;
  font-size: 0;
  margin: 0 100px;
}

.m-table {
  width: 100%;
  font-size: 14px;
  background-color: #fff;
  text-align: center;
  border-spacing: 0;
  border: none;
}
